const { COOKIE_CONSENT_NAME, IS_PROD } = require('../../config');

let localDeviceId = null;

export const getSessionId = () => {
  try {
    return window.localStorage.getItem('sessionId');
  } catch (ex) {
    console.error(ex);
  }
};

export const setSessionId = (sessionId) => {
  try {
    window.localStorage.setItem('sessionId', sessionId);
  } catch (ex) {
    console.error(ex);
  }
};

export const setLoginToken = (loginToken) => {
  try {
    window.localStorage.setItem('loginToken', loginToken);
  } catch (ex) {
    console.error(ex);
  }
};

export const getLoginToken = () => {
  try {
    return window.localStorage.getItem('loginToken');
  } catch (ex) {
    console.error(ex);
  }
};

export const removeLoginToken = () => {
  try {
    window.localStorage.removeItem('loginToken');
  } catch (ex) {
    console.error(ex);
  }
};

export const removeSessionId = () => {
  try {
    window.localStorage.removeItem('sessionId');
  } catch (ex) {
    console.error(ex);
  }
};

export const setCookie = (name, value, expires) => {
  const host = window.location.host;
  const domain = (host.match(/\w+\.\w+$/) || [])[0];

  document.cookie = `${name}=${value};${
    IS_PROD ? `domain=.${domain};` : ''
  }expires=${expires};path=/`;
};

export const getCookie = (cookieName) => {
  const cookiePart = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
  return cookiePart ? cookiePart.pop() : '';
};

export const getConsentCookie = () => {
  return getCookie(COOKIE_CONSENT_NAME);
};

export const setDeviceIdCookie = () => {
  const dateOneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  setCookie('deviceId', localDeviceId, dateOneYearFromNow.toUTCString());
};

export const getDeviceId = () => {
  let deviceId = getCookie('deviceId');

  if (!deviceId && localDeviceId) {
    deviceId = localDeviceId;
  } else if (deviceId && !localDeviceId) {
    localDeviceId = deviceId;
  }

  if (!deviceId) {
    localDeviceId = Math.floor(Math.random() * 9000000000) + 1 + '-' + Date.now();
  }

  return localDeviceId;
};
