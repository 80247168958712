const { API_HOST, PAYMENT_TYPES } = require('../../config');

const extendOptions = (opt, skipContentType = false) => ({
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'omit',
  ...(skipContentType
    ? {}
    : {
        headers: {
          'Content-Type': 'application/json',
        },
      }),
  // redirect: 'follow', // manual, *follow, error
  // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  ...opt,
});

const commonDataResponse = async (res, actOn401 = true) => {
  const { status, headers } = res;
  const contentType = headers.get('Content-Type') || '';
  const isJson = contentType.startsWith('application/json');

  if (status === 401 && actOn401) {
    const sessionId = window.localStorage.getItem('sessionId');
    if (sessionId) {
      await logout(sessionId);
    }
    window.localStorage.removeItem('sessionId');
    window.localStorage.removeItem('loginToken');
    window.location.href = '/';
    return;
  }
  if (status >= 400) {
    let err;

    if (isJson) {
      const errJson = await res.json();
      const errorMessage = errJson.message ? errJson.message : errJson.error.message;
      err = new Error(errorMessage);

      if (errJson.errors) {
        err.fields = errJson.errors.reduce(
          (fields, { field, error }) => ({
            ...fields,
            [field]: error,
          }),
          {}
        );
      }
    } else {
      const errText = await res.text();
      err = new Error(errText);
      err.fields = {};
    }
    err.statusCode = res.status;

    throw err;
  } else if (status === 204) {
    return null;
  }

  return isJson ? res.json() : res.text();
};
const toUrl = (path) => `${path.startsWith('/') ? API_HOST : ''}${path}`;

async function del(path) {
  return fetch(toUrl(path), extendOptions({ method: 'DELETE' }, true)).then(commonDataResponse);
}
async function get(path, skipContentType) {
  return fetch(toUrl(path), extendOptions({ method: 'GET' }, skipContentType)).then(
    commonDataResponse
  );
}
async function post(path, data = {}, ignore401 = false) {
  return fetch(toUrl(path), extendOptions({ method: 'POST', body: JSON.stringify(data) })).then(
    ignore401 ? (r) => r : commonDataResponse
  );
}
async function put(path, data = {}) {
  return fetch(toUrl(path), extendOptions({ method: 'PUT', body: JSON.stringify(data) })).then(
    commonDataResponse
  );
}

function toObject(data) {
  if (typeof data === 'string') {
    try {
      return JSON.parse(data);
    } catch (_) {}
  }
  return data;
}

export const requestPasswordReset = (email) => get(`/v2/web/resetPassword/${email}`, true);
export const resetPassword = (password, token) =>
  post(`/v2/web/resetPassword`, { password, token });
export const loginWithLoginToken = (email, password) => {
  return fetch(
    toUrl('/v2/web/lt/login'),
    extendOptions({
      method: 'POST',
      body: JSON.stringify({ email, password }),
    })
  )
    .then((res) => commonDataResponse(res, false))
    .then(toObject);
};

export const createStripeSubscription = (paymentMethodId, sessionId) =>
  post(`/v2/subscriptions/stripe?sessionId=${sessionId}`, { paymentMethodId });

export const stripeSubscriptionComplete = (sessionId) =>
  put(`/v2/users/stripe?sessionId=${sessionId}`, {});

export const loginWithToken = (token) =>
  post('/v2/web/tokenLogin', { loginToken: token }).then(toObject);

export const createSessionIdFromLoginToken = (loginToken) => {
  return post('/v2/web/lt/session', { loginToken }).then(toObject);
};

// TODO update to new endpoint when available
export const createLoginTokenFromSession = (sessionId) =>
  post(`/v2/web/lt/loginToken?sessionId=${sessionId}`).then(toObject);

export const logout = (sessionId) => post(`/v2/web/logout?sessionId=${sessionId}`, {}, true);
export const me = (sessionId) => get(`/v2/web/me?sessionId=${sessionId}`);
export const cancelSubscription = async (userId, reason, sessionId, paymentType) => {
  if (paymentType === PAYMENT_TYPES.STRIPE) {
    const res = await del(`/v2/subscriptions/stripe?sessionId=${sessionId}`);
    await sendUserReason(reason, sessionId);
    return res;
  } else if (paymentType === PAYMENT_TYPES.KLARNA) {
    const res = await del(`/v2/subscriptions/klarna?sessionId=${sessionId}`);
    await sendUserReason(reason, sessionId);
    return res;
  } else if (paymentType === PAYMENT_TYPES.MONDIDO) {
    const res = await del(`/v2/web/users/${userId}/subscription?sessionId=${sessionId}`);
    await sendUserReason(reason, sessionId);
    return res;
  }
};
export const sendUserReason = async (reason, sessionId) =>
  post(`/v2/web/analytics?sessionId=${sessionId}`, [
    { name: 'cancellation_reason', parameters: { reason } },
  ]);
export const sendUserFeedback = async (parameters, sessionId) =>
  post(`/v2/web/analytics?sessionId=${sessionId}`, [
    { name: 'cancellation_feedback', parameters: parameters },
  ]);

export const sendRequestSupport = async (feedback, sessionId) =>
  post(`/v2/users/support?sessionId=${sessionId}`, { feedback });

export const getCurrentOffer = (sessionId) => {
  return get(`/v2/users/churn-recovery/current-offer?sessionId=${sessionId}`).catch(() => {
    return { status: 'error' };
  });
}

export const getCancellationOffer = (sessionId) => {
  return get(`/v2/users/churn-recovery/discount?sessionId=${sessionId}`).catch(() => {
    return { status: 'error' };
  });
}

export const applyDiscountOffer = (offer, sessionId) => 
  put(`/v2/users/discount?sessionId=${sessionId}`, { offer });


export const updateEmail = (email, password, sessionId) =>
  put(`/v3/mypages/user/email?sessionId=${sessionId}`, { email, password });
export const updatePassword = (oldP, newP, sessionId) =>
  put(`/v2/web/password?sessionId=${sessionId}`, { password: { old: oldP, new: newP } });

export const postTrackingEvent = (data, sessionId) => {
  const parsedBody = data.map((item) => ({
    ...item,
    parameters: Object.entries(item.parameters).reduce(
      (obj, [k, v]) => ({
        ...obj,
        [k]: Array.isArray(v)
          ? v.map((o) => (o && typeof o === 'object' ? JSON.stringify(o) : o))
          : v,
      }),
      {}
    ),
  }));
  return post(`/v2/web/analytics` + (sessionId ? `?sessionId=${sessionId}` : ''), parsedBody);
};

export const getSubscription = (loginToken, region = 'SWE') => {
  return get(`/v3/mypages/subscription/logintoken?loginToken=${loginToken}&region=${region}`).catch(
    () => {
      return '';
    }
  );
};

export const updateSubscription = async (sessionId, data) =>
  put(`/v3/mypages/subscription?sessionId=${sessionId}`, data);

export const getAppToken = async (loginToken, region = 'SWE') => {
  return fetch(`https://app-api.boksnok.se/v2/user/login/app/token?lt=${loginToken}&region=${region}`, extendOptions({ method: 'GET' })).then(
    commonDataResponse
  );
}

export const getReceipts = (loginToken, region) => {
  return get(`/v3/mypages/receipts?loginToken=${loginToken}&region=${region}`).catch(() => {
    return { status: 'error' };
  });
};
