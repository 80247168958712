import { REGION } from '../../config';
import { getConsentCookie, getDeviceId, getSessionId, setDeviceIdCookie } from './storage';
import { postTrackingEvent } from './api';

export const checkEmail = (email) => {
  const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
};

export const getDateTextFromString = (text) => {
  const date = new Date(text);

  if (date.toString() === 'Invalid Date') {
    return text;
  }

  return getDateText(date);
};

export const getDateText = (date = new Date()) => {
  return date.toISOString().substring(0, 10);
};

export const getDateTextFromMS = (milliseconds = null) => {
  // Some numbers can come as seconds, correct for this:
  if (milliseconds > 1500000000 && milliseconds < 1700000000) {
    milliseconds *= 1000;
  }
  return milliseconds ? getDateText(new Date(milliseconds)) : null;
};

export const getDateDayDiff = (date1, date2 = new Date()) => {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const getRelativeDateText = (date) => {
  const days = getDateDayDiff(date);

  if (days > 50) {
    const months = Math.round(days / 30);
    return `Inom ${months} månad${months > 1 ? 'er' : ''}`;
  }

  if (days > 15) {
    const weeks = Math.round(days / 7);
    return `Inom ${weeks} ${weeks > 1 ? 'veckor' : 'vecka'}`;
  }

  return `Inom ${days} dag${days > 1 ? 'ar' : ''}`;
};

let queryUtmObject;
let utmTagObject;
let trackingEventList = [];

export const sendTrackingEvent = async (eventName, extraParameters, autoConsent) => {
  const host_name = window.location.hostname;
  const path_name = window.location.pathname;
  const page_url = window.location.href
    .replace(/lt=[^&]*/, 'lt=<hidden>') // Remove lt param
    .replace(/sessionId=[^&]*/, 'sessionId=<hidden>') // Remove lt param
    .replace(/secret=[^&]*/, 'secret=<hidden>'); // Remove lt param
  let parameters = {
    host_name,
    path_name,
    page_url,
    region: REGION,
    source: 'web',
  };

  if (queryUtmObject) {
    utmTagObject = queryUtmObject;
  } else {
    utmTagObject = localStorage.getItem('_utm-info');
  }

  if (extraParameters) {
    try {
      for (const [key] of Object.entries(extraParameters)) {
        parameters[key] = extraParameters[key];
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (utmTagObject) {
    try {
      const parsedUtmObject = JSON.parse(utmTagObject);
      for (const [key] of Object.entries(parsedUtmObject)) {
        parameters[`utm_${key}`] = parsedUtmObject[key];
      }
      delete parameters['utm_timestamp'];
    } catch (e) {}
  }

  let data = [
    {
      name: eventName,
      parameters: parameters,
      timestamp: Date.now(),
      internal_device_id: getDeviceId(),
    },
  ];

  const cookieControl = getConsentCookie();
  if (autoConsent || ['true', 'yes', 'granted'].includes((cookieControl || '').toLowerCase())) {
    trackingEventList.push(data);
    sendTrackingEventOnConsent();
  } else if (!cookieControl) {
    trackingEventList.push(data);
  }
};

export let sendConsentSelectionEvent = async (accepted) => {
  const data = [
    {
      name: 'cookie_consent',
      anonymous: true,
      parameters: {
        consent: accepted,
        region: REGION,
      },
    },
  ];
  return postTrackingEvent(data, null);
};

export let sendTrackingEventOnConsent = async () => {
  const sessionId = getSessionId();

  setDeviceIdCookie();

  await Promise.all(
    trackingEventList.map(async (data) => {
      return postTrackingEvent(data, sessionId);
    })
  );
  trackingEventList = [];
};

export const getUtmTags = () => {
  let utm_source;
  let utm_campaign;
  let utm_medium;
  let utm_term;
  let utm_content;

  try {
    const urlParams = new URLSearchParams(window.location.search);
    utm_source = urlParams.get('utm_source');
    utm_campaign = urlParams.get('utm_campaign');
    utm_medium = urlParams.get('utm_medium');
    utm_term = urlParams.get('utm_term');
    utm_content = urlParams.get('utm_content');

    if (utm_source) {
      queryUtmObject = {
        '_utm-info': JSON.stringify({
          source: utm_source,
          medium: utm_medium,
          term: utm_term,
          content: utm_content,
          campaign: utm_campaign,
          timestamp: Date.now(),
        }),
      };
    }
  } catch (e) {}
};
